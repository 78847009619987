import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input, Typography, ImageUpload } from '@weareopenr/component-library';
import ModalImg from 'src/assets/images/create-brand-modal.png';
import styles from './BrandModal.module.scss';
import ModalWrapper from 'src/components/ModalWrapper';
import { IBrandResponse } from 'src/api/getBrands/types';
import { IFormValues, IBrandModalProps } from './types';
import { useCreateBrand, useUpdateBrand } from 'src/hooks';

const BrandModal = ({ refetch, closeModal, brands, variant, currentBrand }: IBrandModalProps) => {
  const onMutationSuccess = (): void => {
    refetch();
    closeModal();
  };
  const { mutate: createBrandMutate, isLoading: isCreateBrandLoading } =
    useCreateBrand(onMutationSuccess);
  const { mutate: updateBrandMutate, isLoading: isUpdateBrandLoading } =
    useUpdateBrand(onMutationSuccess);

  const initialLogo = currentBrand?.logo_url || null;
  const [uploadedLogo, setUploadedLogo] = useState<File | string | null>(initialLogo);
  const isEdit = variant === 'update';
  const isImageObject = typeof uploadedLogo === 'object';

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<IFormValues>({
    defaultValues: {
      name: currentBrand?.name || '',
    },
    mode: 'onChange',
  });

  const onSubmit = (data: IFormValues) => {
    const isNameChanged = currentBrand?.name !== data.name;
    const isLogoChanged = initialLogo !== uploadedLogo;
    const isFormUpdated = isNameChanged || isLogoChanged;

    if (isEdit && !isFormUpdated) {
      closeModal();
    } else if (isEdit && isFormUpdated && currentBrand) {
      updateBrandMutate({
        id: currentBrand.brand_id,
        ...(isNameChanged && { name: data.name }),
        ...(isLogoChanged &&
          isImageObject && {
            file: uploadedLogo,
          }),
      });
    } else {
      createBrandMutate({
        name: data.name,
        ...(uploadedLogo && isImageObject ? { file: uploadedLogo } : {}),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography size="XXL" weight="semi-bold" className={styles['heading-text']}>
        {`${isEdit ? 'Edit' : 'Create'} brand`}
      </Typography>
      <div className={styles['input-name']}>
        <Input
          type="text"
          label="Name"
          register={register('name', {
            required: 'Please enter a name',
            validate: {
              nameIsUnique: (value: string, values: IFormValues) =>
                currentBrand?.name !== values.name &&
                brands?.some(
                  (brand: IBrandResponse) => brand?.name.toLowerCase() === value.toLowerCase(),
                )
                  ? 'A Brand with this name already exists'
                  : true,
            },
          })}
          error={errors?.name?.message}
        />
        <ImageUpload
          title="Logo"
          prompt="Add logo"
          image={uploadedLogo}
          setImage={setUploadedLogo}
        />
      </div>
      <Button
        data-cy="create-button"
        loading={isCreateBrandLoading || isUpdateBrandLoading}
        primary
        size="full-width"
        type="submit"
      >
        {isEdit ? 'Save' : 'Create'}
      </Button>
    </form>
  );
};
const WrappedModal = ({
  closeModal,
  refetch,
  open,
  brands,
  variant,
  currentBrand,
}: IBrandModalProps): ReactElement => {
  return (
    <ModalWrapper closeModal={closeModal} open={open} image={ModalImg} imageAlt="Picture of Sides">
      <BrandModal
        open={open}
        refetch={refetch}
        closeModal={closeModal}
        brands={brands}
        variant={variant}
        currentBrand={currentBrand}
      />
    </ModalWrapper>
  );
};

export default WrappedModal;
