import { fetch } from '../fetchWrapper/fetch';
import { API_URL } from '../../constants';

export const deleteChannel = async (channelId: string): Promise<boolean> => {
  try {
    const res = await fetch(`${API_URL}/channels/${channelId}`, { method: 'DELETE' });
    return res.status === 204;
  } catch (e) {
    throw new Error(e.message);
  }
};
