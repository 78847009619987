import { useMutation } from '@tanstack/react-query';
import { updateBrand, IUpdateBrandResponse } from 'src/api/updateBrand';
import { useNotifications } from '@weareopenr/component-library';

export const useUpdateBrand = (onMutationSuccess: () => void) => {
  const setMessage = useNotifications();

  return useMutation(
    (params: { id: string; name?: string; file?: File | null }) =>
      updateBrand(params.id, params.name, params.file),
    {
      onSuccess: (data: IUpdateBrandResponse) => {
        setMessage({
          message: (
            <>
              <strong>{data.name}</strong> has successfully been edited.
            </>
          ),
          severity: 'success',
        });
        onMutationSuccess();
      },
      onError: (error, req) => {
        setMessage({
          message: (
            <>
              Unable to edit <strong>{req.name}</strong>
            </>
          ),
          severity: 'error',
        });
      },
    },
  );
};
