import { getLogo } from 'src/utils/getLogo';
import { IBrandResponse, IChannelResponse, IConfiguredAddons } from '../../api/getBrands/types';
import styles from './Channels.module.scss';
import { Typography, getDefaultLogo } from '@weareopenr/component-library';

export const Channels = ({ brand }: { brand: IBrandResponse }) => {
  const channels: IChannelResponse[] = brand.channels;
  const addons: IConfiguredAddons[] = brand.configured_addons;
  const onRow = channels.length + addons.length > 1 ? 'channel-row' : 'on-first-row';
  return (
    <div className={styles['channel-container']}>
      {channels.map((channel: IChannelResponse) => {
        return (
          <div key={channel.channel_id} className={styles[onRow]}>
            <img
              src={getLogo(channel.logo_url, channel.name)}
              alt={`${channel.name} logo`}
              className={styles['channel-logo']}
            />
            <Typography size="M">{channel.name}</Typography>
          </div>
        );
      })}
      {addons.map((addon: IConfiguredAddons) => {
        return (
          <div key={addon.configuration_id} className={styles[onRow]}>
            <img
              src={getDefaultLogo('')}
              alt={`${addon.preferred_name} logo`}
              className={styles['channel-logo']}
            />
            <Typography size="M">{addon.preferred_name}</Typography>
          </div>
        );
      })}
    </div>
  );
};
