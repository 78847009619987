import { ICommon, TAddChannelToBrandRequest } from 'src/api/addChannelToBrand/types';
import { TAuthTypes } from 'src/types';
import { IFormData } from '../types';

export const buildAddPayload = (
  data: IFormData,
  authenticationType: TAuthTypes | undefined | null,
  brandId: string,
): TAddChannelToBrandRequest => {
  const { addonId, preferredName, username } = data;
  const { password, contents, accessToken } = data.secrets;

  const payload: ICommon = {
    brand_id: brandId,
    addon_id: addonId,
    authentication_type: authenticationType ?? null,
    preferred_name: preferredName,
  };

  if (authenticationType === 'BASIC') {
    return { ...payload, username, password };
  } else if (authenticationType === 'TOKEN') {
    return { ...payload, access_token: accessToken };
  } else if (authenticationType === 'CERTIFICATE') {
    return { ...payload, contents };
  }
  return payload;
};
