import { fetch } from '../fetchWrapper/fetch';
import { IUpdateBrandResponse } from './types';
import { API_URL } from '../../constants';
import { IResMeta } from 'src/types';

export const updateBrand = async (
  brandId: string,
  brandName?: string,
  image?: File | null,
): Promise<IUpdateBrandResponse> => {
  const formData = new FormData();
  if (brandName) {
    formData.append('name', brandName);
  }
  if (image !== undefined) {
    formData.append('file', image || 'null');
  }

  try {
    const res = await fetch(`${API_URL}/brands/${brandId}`, {
      method: 'PATCH',
      body: formData,
    });
    if (res.status !== 200) {
      throw new Error();
    }
    const json: IResMeta<IUpdateBrandResponse[]> = await res.json();
    return json.data[0];
  } catch (e) {
    throw new Error('Could not update brand');
  }
};
