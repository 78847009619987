import { ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { Button, Input, Select, Typography, useNotifications } from '@weareopenr/component-library';
import { IConfiguredAddons } from 'src/api/getBrands/types';
import { addChannelToBrand, TAddChannelToBrandRequest } from 'src/api/addChannelToBrand';
import { ErrorMessages } from 'src/api/constants';
import { updateChannel, TUpdateChannelToBrandRequest } from 'src/api/updateChannel';
import { buildAddPayload } from './buildAddPayload/buildAddPayload';
import { buildEditPayload } from './buildEditPayload/buildEditPayload';
import { toSnakeCase } from 'src/utils/toSnakeCase/toSnakeCase';
import { IFormData, IModal, TCommonFieldsKeys, TSnakeCaseKeys } from './types';
import styles from './ChannelModal.module.scss';

export const ModalContent = ({
  currentBrand,
  refetch,
  closeModal,
  data,
  isFetching,
  variant,
  configurationId,
}: Omit<IModal, 'open'>): ReactElement => {
  const setMessage = useNotifications();
  const title = `${variant} channel`;
  const isEdit = variant === 'Edit';
  const placeholder = isEdit ? '*************' : ' ';

  const closeModalAndRefetch = () => {
    closeModal();
    refetch();
  };

  const { mutate: addChannelMutate, isLoading: isAddLoading } = useMutation(
    (variables: TAddChannelToBrandRequest) => addChannelToBrand(variables),
    {
      onSuccess: () => {
        closeModalAndRefetch();
        setMessage({
          message: (
            <Typography size="M" weight="regular" color="secondary">
              A channel has successfully been added.
            </Typography>
          ),
          severity: 'success',
        });
      },
      onError: (e: Error) => {
        setMessage({
          message: e.message,
          severity: 'error',
          description: ErrorMessages.CONTACT_ADMIN,
        });
      },
    },
  );

  const { mutate: editChannelMutate, isLoading: isEditLoading } = useMutation(
    (params: { id: string; data: TUpdateChannelToBrandRequest }) =>
      updateChannel(params.id, params.data),
    {
      onSuccess: (data) => {
        closeModalAndRefetch();
        setMessage({
          message: (
            <Typography size="M" weight="regular" color="secondary">
              <strong>{data.preferred_name}</strong> channel has successfully been updated.
            </Typography>
          ),
          severity: 'success',
        });
      },
      onError: (e: Error) => {
        setMessage({
          message: e.message,
          severity: 'error',
          description: ErrorMessages.CONTACT_ADMIN,
        });
      },
    },
  );

  const defaultValues: IFormData = {
    addonId: '',
    preferredName: '',
    username: '',
    secrets: {
      password: '',
      contents: '',
      accessToken: '',
    },
  };

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    control,
    register,
    setValue,
  } = useForm<IFormData>({
    defaultValues,
    mode: 'onChange',
  });
  const channelId = watch('addonId');
  const channel = data?.find((channel) => channel.addon_id === channelId);
  const currentConfiguredAddon = currentBrand.configured_addons.find(
    (addon) => addon.configuration_id === configurationId,
  );

  const authenticationType = channel?.authentication_types && channel?.authentication_types[0];

  const options = (data ?? []).map((channel) => ({
    value: channel.addon_id,
    name: channel.addon_name,
  }));

  const currentChannel = isEdit ? currentConfiguredAddon : channel;

  const currentAuthenticationType = isEdit
    ? currentConfiguredAddon?.authentication_type
    : authenticationType;

  const onPopulateFields = () => {
    const commonFieldsToPopulate = Object.keys(defaultValues).filter(
      (key) => key !== 'secrets' && key !== 'addonId',
    ) as TCommonFieldsKeys[];

    commonFieldsToPopulate.forEach((key: TCommonFieldsKeys) => {
      setValue(key, `${(currentChannel as IConfiguredAddons)[toSnakeCase(key) as TSnakeCaseKeys]}`);
    });
  };

  useEffect(() => {
    if (isEdit) {
      onPopulateFields();
    }
  }, [variant]);

  useEffect(() => {
    if (channelId && !isEdit) {
      setValue('preferredName', `${currentChannel?.addon_name}`, { shouldValidate: true });
    }
  }, [channelId]);

  const onSubmit = (data: IFormData) => {
    isEdit
      ? editChannelMutate({
          id: configurationId as string,
          data: buildEditPayload(
            data,
            currentChannel as IConfiguredAddons,
            dirtyFields as unknown as IFormData,
          ) as TUpdateChannelToBrandRequest,
        })
      : addChannelMutate(buildAddPayload(data, currentAuthenticationType, currentBrand.brand_id));
  };

  {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography size="XXL" weight="semi-bold" className={styles['heading-text']}>
          {title}
        </Typography>
        {!isEdit && (
          <div className={styles['input']}>
            <Controller
              name="addonId"
              rules={{
                required: 'Please select a channel',
              }}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  label="Channel"
                  options={options}
                  error={error?.message}
                  loading={isFetching}
                />
              )}
            />
          </div>
        )}

        {currentChannel && (
          <div className={styles['input']}>
            <Input
              type="text"
              label="Preferred name"
              register={register('preferredName', {
                required: 'Please enter a name',
                validate: {
                  nameIsUnique: (value) =>
                    currentBrand?.configured_addons?.some(
                      (addon: IConfiguredAddons) =>
                        !(
                          isEdit &&
                          currentConfiguredAddon?.preferred_name.toLowerCase() ===
                            addon.preferred_name.toLowerCase()
                        ) && addon.preferred_name.toLowerCase() === value.toLowerCase(),
                    )
                      ? 'A channel with this preferred name already exists'
                      : true,
                },
              })}
              error={errors?.preferredName?.message}
            />
          </div>
        )}
        {currentAuthenticationType && (
          <div className={styles['credential-container']}>
            <Typography className={styles['credential-text']} weight="semi-bold" size="M">
              Login credentials
            </Typography>
            {currentAuthenticationType === 'BASIC' && (
              <>
                <div className={styles['input']}>
                  <Input
                    type="text"
                    label="Username"
                    register={register('username', {
                      required: 'Please enter a username',
                    })}
                    error={errors?.username?.message}
                  />
                </div>
                <div className={styles['input']}>
                  <Input
                    type="password"
                    placeholder={placeholder}
                    label="Password"
                    register={register('secrets.password', {
                      required: !isEdit && 'Please enter a password',
                    })}
                    error={errors?.secrets?.password?.message}
                  />
                </div>
              </>
            )}
            {currentAuthenticationType === 'CERTIFICATE' && (
              <div className={styles['input']}>
                <Input
                  type="text"
                  label="Contents"
                  placeholder={placeholder}
                  register={register('secrets.contents', {
                    required: !isEdit && 'Please enter contents',
                  })}
                  error={errors?.secrets?.contents?.message}
                />
              </div>
            )}
            {currentAuthenticationType === 'TOKEN' && (
              <div className={styles['input']}>
                <Input
                  type="text"
                  label="Token"
                  placeholder={placeholder}
                  register={register('secrets.accessToken', {
                    required: !isEdit && 'Please enter a token',
                  })}
                  error={errors?.secrets?.accessToken?.message}
                />
              </div>
            )}
          </div>
        )}

        <div className={styles['controls']}>
          <Button size="full-width" onClick={closeModal}>
            Back
          </Button>
          <Button size="full-width" type="submit" primary loading={isEditLoading || isAddLoading}>
            {isEdit ? 'Continue' : 'Add'}
          </Button>
        </div>
      </form>
    );
  }
};
