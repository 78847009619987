import { fetch } from '../fetchWrapper/fetch';
import { IBrandResponse } from './types';
import { API_URL } from '../../constants';
import { ErrorMessages } from '../constants';
import { IResMeta } from 'src/types';

export const getBrands = async (): Promise<IBrandResponse[]> => {
  try {
    const res = await fetch(`${API_URL}/brands`);
    if (res.status !== 200) {
      const error: { message: string } = await res.json();
      throw new Error(error.message);
    }
    const json: IResMeta<IBrandResponse[]> = await res.json();
    return json.data;
  } catch (e) {
    throw new Error(e?.message || ErrorMessages.SORRY_SOMETHING_WENT_WRONG);
  }
};
