import { fetch } from '../fetchWrapper/fetch';
import { IChannelsAddonsResponse } from './types';
import { API_URL } from '../../constants';
import { IResMeta } from 'src/types';

export const getChannelsAddons = async (): Promise<IChannelsAddonsResponse[]> => {
  try {
    const res = await fetch(`${API_URL}/marketplace/addons`);
    if (res.status !== 200) {
      throw new Error();
    }
    const json: IResMeta<IChannelsAddonsResponse[]> = await res.json();
    return json.data;
  } catch (e) {
    throw new Error('Could not fetch Channels addons');
  }
};
