import { IChannelToBrandResponse, IResMeta } from 'src/types';
import { API_URL } from '../../constants';
import { fetch } from '../fetchWrapper/fetch';
import { TAddChannelToBrandRequest } from './types';
import { ErrorMessages } from '../constants';

export const addChannelToBrand = async (
  payload: TAddChannelToBrandRequest,
): Promise<IChannelToBrandResponse> => {
  try {
    const res = await fetch(`${API_URL}/channels`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(payload),
    });
    if (res.status !== 200) {
      const error: { message: string } = await res.json();
      throw new Error(error.message);
    }
    const json: IResMeta<IChannelToBrandResponse[]> = await res.json();
    return json.data[0];
  } catch (e) {
    throw new Error(e?.message || ErrorMessages.ERROR_ADD_CHANNELS);
  }
};
