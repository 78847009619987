import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeading, Table, useNotifications } from '@weareopenr/component-library';
import { getBrands } from '../../api/getBrands/getBrands';
import { useQuery } from '@tanstack/react-query';
import styles from './Dashboard.module.scss';
import { CircularProgress } from '@mui/material';
import BrandModal from 'src/components/BrandModal';
import { mapToTable } from './mapToTable/mapToTable';
import { ErrorMessages } from '../../api/constants';

export const Dashboard = () => {
  const setMessage = useNotifications();
  const history = useHistory();
  const [openBrandModal, setOpenBrandModal] = useState<boolean>(false);

  const handleModalToggle = (): void => {
    setOpenBrandModal((prev) => !prev);
  };

  const onView = (brandId: string): void => {
    history.push(`/brands/${brandId}`);
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['brands'],
    queryFn: getBrands,
    onError: (e: Error) => {
      setMessage({
        message: e.message,
        severity: 'error',
        description: ErrorMessages.CONTACT_ADMIN,
      });
    },
  });

  return (
    <>
      {isLoading ? (
        <div className={styles.spinner}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <PageHeading title="Brands" onPlusClick={handleModalToggle} />
          <BrandModal
            open={openBrandModal}
            closeModal={handleModalToggle}
            refetch={refetch}
            brands={data}
            variant="create"
          />
          {data && (
            <Table data={mapToTable(data, onView)} verticalAlign="middle" onRowClick={onView} />
          )}
        </>
      )}
    </>
  );
};
