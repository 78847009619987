import { API_URL } from 'src/constants';
import { fetch } from '../fetchWrapper/fetch';
import { IChannelToBrandResponse, IResMeta } from 'src/types';
import { TUpdateChannelToBrandRequest } from './types';
import { ErrorMessages } from '../constants';

export const updateChannel = async (
  configurationId: string,
  requestBody: TUpdateChannelToBrandRequest,
): Promise<IChannelToBrandResponse> => {
  const url = `${API_URL}/channels/${configurationId}`;

  try {
    const res = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(requestBody),
      headers: {
        'content-type': 'application/json',
      },
    });
    if (res.status !== 200) {
      const error: { message: string } = await res.json();
      throw new Error(error.message);
    }
    const json: IResMeta<IChannelToBrandResponse[]> = await res.json();
    return json.data[0];
  } catch (e) {
    throw new Error(e?.message || ErrorMessages.ERROR_EDIT_CHANNELS);
  }
};
