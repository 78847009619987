import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { match, useHistory } from 'react-router-dom';
import { getBrands } from '../../api/getBrands/getBrands';
import { CircularProgress } from '@mui/material';
import styles from './Brand.module.scss';
import {
  EmptyStateButton,
  FormatInformation,
  NumericalSubtitle,
  PageHeading,
  Prompt,
  useNotifications,
} from '@weareopenr/component-library';
import { IConfiguredAddons } from 'src/api/getBrands/types';
import { getLogo } from 'src/utils/getLogo';
import ChannelModal from 'src/components/ChannelModal';
import { TVariant } from 'src/types';
import { ChannelSummary } from '../../components/ChannelSummary/ChannelSummary';
import { deleteChannel } from '../../api/deleteChannel/deleteChannel';
import BrandModal from 'src/components/BrandModal';

export interface IParams {
  brandId: string;
}

export const Brand = ({ match }: { match: match<IParams> }) => {
  const createNotification = useNotifications();
  const [channelToDelete, setChannelToDelete] = useState<string | null>(null);
  const history = useHistory<string>();
  const [isChannelModalOpen, setIsChannelModalOpen] = useState<boolean>(false);
  const [variant, setVariant] = useState<TVariant>('Add');
  const [isBrandModalOpen, setIsBrandModalOpen] = useState<boolean>(false);

  const handleChannelModalToggle = (): void => setIsChannelModalOpen((prev: boolean) => !prev);
  const handleBrandModalToggle = (): void => setIsBrandModalOpen((prev: boolean) => !prev);

  const onChannelModal = (value: TVariant, id?: string): void => {
    id && history.push({ state: id });
    setVariant(value);
    handleChannelModalToggle();
  };

  const { data, refetch } = useQuery({
    queryKey: ['brands'],
    queryFn: getBrands,
    onError: () =>
      createNotification({
        severity: 'error',
        message: 'Something went wrong whilst fetching brands',
      }),
  });

  const { mutate } = useMutation((channelId: string) => deleteChannel(channelId), {
    onSuccess: () => {
      setChannelToDelete(null);
      createNotification({ severity: 'success', message: 'Channel successfully deleted' });
      refetch();
    },
    onError: () => {
      createNotification({
        severity: 'error',
        message: 'Something went wrong whilst deleting channel',
      });
    },
  });

  const brandId = match?.params?.brandId;
  const currentBrand = data && data.find((brand) => brand?.brand_id === brandId);
  const onBackClick = (): void => {
    history.push('/');
  };

  return (
    <>
      {!currentBrand ? (
        <div className={styles['spinner']}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <PageHeading title={currentBrand.name} onBackClick={onBackClick} />
          <div className={styles['section']}>
            <NumericalSubtitle number="1" subtitle="Details" onEditClick={handleBrandModalToggle} />
            <div className={styles['item-detail-container']}>
              <div>
                <FormatInformation heading="Name" information={currentBrand.name} />
                <FormatInformation
                  heading="Venues"
                  information={currentBrand.venues > 0 && currentBrand.venues}
                />
              </div>
              <img
                className={styles['logo-image']}
                src={getLogo(currentBrand?.logo_url, '')}
                alt={`Image of ${currentBrand.name}`}
              />
            </div>
          </div>

          <NumericalSubtitle number="2" subtitle="Channels" />
          {currentBrand?.configured_addons.map((addon: IConfiguredAddons) => (
            <ChannelSummary
              key={addon.configuration_id}
              channelId={addon.configuration_id}
              channelName={addon.preferred_name}
              onDeleteClick={setChannelToDelete}
              onChannelModal={onChannelModal}
            />
          ))}
          <ChannelModal
            variant={variant}
            open={isChannelModalOpen}
            closeModal={handleChannelModalToggle}
            currentBrand={currentBrand}
            refetch={refetch}
            configurationId={history.location.state}
          />
          <EmptyStateButton label="+ Add" onClick={() => onChannelModal('Add')} />
          <BrandModal
            open={isBrandModalOpen}
            closeModal={handleBrandModalToggle}
            refetch={refetch}
            brands={data}
            variant="update"
            currentBrand={currentBrand}
          />

          <Prompt
            closePrompt={() => setChannelToDelete(null)}
            open={Boolean(channelToDelete)}
            buttonHandler={() => mutate(channelToDelete || '')}
            buttonText="Delete"
            promptHeading={`Are you sure you want to delete ${
              currentBrand.configured_addons.find(
                (addon) => addon.configuration_id === channelToDelete,
              )?.addon_name
            }?`}
          >
            You will permanently remove this channel.
          </Prompt>
        </>
      )}
    </>
  );
};
