import { EllipsisMenu, getDefaultLogo, Typography } from '@weareopenr/component-library';
import styles from './ChannelSummary.module.scss';
import { Dispatch, SetStateAction } from 'react';
import { TVariant } from 'src/types';

interface IChannelSummaryProps {
  channelId: string;
  channelName: string;
  onDeleteClick: Dispatch<SetStateAction<string | null>>;
  onChannelModal: (value: TVariant, id?: string) => void;
}

export const ChannelSummary = ({
  channelId,
  channelName,
  onDeleteClick,
  onChannelModal,
}: IChannelSummaryProps) => {
  const options = [
    {
      id: channelId,
      name: 'Edit',
      onOptionClick: () => onChannelModal('Edit', channelId),
    },
    {
      id: 'delete',
      name: 'Delete',
      onOptionClick: () => onDeleteClick(channelId),
      isHighlighted: true,
    },
  ];

  return (
    <div className={styles['channel-content']}>
      <div className={styles['info-container']}>
        <img className={styles['logo']} src={getDefaultLogo('')} alt={`Image of ${channelName}`} />
        <Typography weight="semi-bold">{channelName}</Typography>
      </div>
      <EllipsisMenu options={options} />
    </div>
  );
};
