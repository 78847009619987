import { fetch } from '../fetchWrapper/fetch';
import { ICreateBrandResponse } from './types';
import { API_URL } from '../../constants';
import { IResMeta } from 'src/types';

export const createBrand = async (
  brandName: string,
  image?: File,
): Promise<ICreateBrandResponse> => {
  const formData = new FormData();
  formData.append('data', JSON.stringify({ name: brandName }));
  if (image) {
    formData.append('file', image);
  }

  try {
    const res = await fetch(`${API_URL}/brands`, {
      method: 'POST',
      body: formData,
    });
    if (res.status !== 200) {
      throw new Error();
    }
    const json: IResMeta<ICreateBrandResponse[]> = await res.json();
    return json.data[0];
  } catch (e) {
    throw new Error('Could not create brand');
  }
};
