import { useMutation } from '@tanstack/react-query';
import { createBrand, ICreateBrandResponse } from 'src/api/createBrand';
import { useNotifications } from '@weareopenr/component-library';

export const useCreateBrand = (onMutationSuccess: () => void) => {
  const setMessage = useNotifications();

  return useMutation(
    (params: { name: string; file?: File }) => createBrand(params.name, params.file),
    {
      onSuccess: (data: ICreateBrandResponse) => {
        setMessage({
          severity: 'success',
          message: (
            <>
              <strong>{data.name}</strong> has successfully been created.
            </>
          ),
        });
        onMutationSuccess();
      },
      onError: (error, req) => {
        setMessage({
          message: (
            <>
              Unable to create <strong>{req.name}</strong>
            </>
          ),
          severity: 'error',
        });
      },
    },
  );
};
