import { ReactElement } from 'react';
import { Close } from '@weareopenr/component-library';
import styles from './ModalWrapper.module.scss';
import { Dialog, IconButton } from '@mui/material';

export interface IModalWrapper {
  closeModal?: () => void;
  open: boolean;
  children: ReactElement;
  image: string;
  imageAlt: string;
}

export const ModalWrapper = ({
  closeModal,
  open,
  children,
  image,
  imageAlt,
}: IModalWrapper): ReactElement => {
  return (
    <Dialog fullScreen open={open}>
      <div className={styles['close-button']}>
        <IconButton data-testid={'close-modal'} aria-label="close" onClick={closeModal}>
          <Close className={styles['close-icon']} />
        </IconButton>
      </div>
      <div className={styles['container']}>
        <div className={styles['image-container']}>
          <img className={styles['image']} loading="lazy" src={image} alt={imageAlt} />
        </div>
        <div className={styles['form-content']}>{children}</div>
      </div>
    </Dialog>
  );
};
