import { IEditCommon, TUpdateChannelToBrandRequest } from 'src/api/updateChannel/types';
import { IFormData, TEditFormDataKeys, TSecretsFieldsKeys, TSnakeCaseKeys } from '../types';
import { toSnakeCase } from 'src/utils/toSnakeCase/toSnakeCase';
import { IConfiguredAddons } from 'src/api/getBrands/types';

export const buildEditPayload = (
  data: IFormData,
  currentChannel: IConfiguredAddons,
  dirtyFields: IFormData,
): TUpdateChannelToBrandRequest => {
  const commonKeys = Object.keys(dirtyFields).filter((key) => key !== 'secrets');
  const secretKeys = Object.keys((dirtyFields?.secrets as unknown as TSecretsFieldsKeys) ?? {});
  const keys = commonKeys.concat(secretKeys);

  const isSecretValueEmpty = secretKeys.find(
    (key) => data.secrets[key as TSecretsFieldsKeys] === '',
  );

  // removes preferredName from Keys if it matches with current channel
  keys.includes('preferredName') &&
    currentChannel.preferred_name === data.preferredName &&
    keys.splice(keys.indexOf('preferredName'), 1);

  let payload: IEditCommon = {};

  if (keys.length > 0) {
    if (secretKeys.length > 0 || keys.includes('username')) {
      payload = { ...payload, authentication_type: currentChannel.authentication_type };
      secretKeys.includes('password') && (payload = { ...payload, username: data.username });
    }

    keys.forEach((key) => {
      const targetKey = secretKeys.includes(key) && !isSecretValueEmpty ? data.secrets : data;
      payload = {
        ...payload,
        [toSnakeCase(key) as TSnakeCaseKeys]: targetKey[key as TEditFormDataKeys],
      };
    });
  }
  return payload;
};
