import { ReactElement } from 'react';
import { useQuery } from '@tanstack/react-query';
import ModalImg from 'src/assets/images/create-brand-modal.png';
import ModalWrapper from 'src/components/ModalWrapper';
import { IModal } from './types';
import { getChannelsAddons } from 'src/api/getChannelsAddons/getChannelsAddons';
import { ModalContent } from './ModalContent';

const ChannelModal = ({
  variant,
  closeModal,
  open,
  currentBrand,
  refetch,
  configurationId,
}: Omit<IModal, 'data'>): ReactElement => {
  const { data, isLoading } = useQuery({
    queryKey: ['channels-addons'],
    queryFn: getChannelsAddons,
  });

  return (
    <ModalWrapper closeModal={closeModal} open={open} image={ModalImg} imageAlt="Picture of Sides">
      <ModalContent
        currentBrand={currentBrand}
        variant={variant}
        refetch={refetch}
        closeModal={closeModal}
        isFetching={isLoading}
        data={data}
        configurationId={configurationId}
      />
    </ModalWrapper>
  );
};

export default ChannelModal;
