export const fetch = (url: string, options?: RequestInit) => {
  const authObj = localStorage.getItem('okta-token-storage');
  const accessToken = authObj ? JSON.parse(authObj).accessToken.accessToken : '' || '';
  const requestOptions: RequestInit = {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return window.fetch(url, requestOptions);
};
