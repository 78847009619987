import { Sidebar, StarCircled } from '@weareopenr/component-library';

interface IAdminAppSidebar {
  open: boolean;
  appName: string;
  currentPath: string;
  onSidebarClick: () => void;
}

const AdminAppSidebar = ({ open, appName, currentPath, onSidebarClick }: IAdminAppSidebar) => {
  const sidebarContent = [
    {
      title: 'Brands',
      path: '/',
      icon: <StarCircled />,
      content: [],
      isSelected: currentPath === '/' || currentPath.includes('brands'),
    },
  ];

  return (
    <Sidebar
      open={open}
      appName={appName}
      homeLinks={sidebarContent}
      currentPath={currentPath}
      onSidebarClick={onSidebarClick}
    />
  );
};

export default AdminAppSidebar;
